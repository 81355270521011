html, body {
  font-family: 'Helvetica Now Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #282c34;
  margin: 0;
  width: 100%;
  height: 100%;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ESTRUCTURA: App { App-header { App-logo App-main } }*/

.App {
  text-align: center;

  width: 100%;
  height: 100%;
}

.App-header {
  font-size: calc(10px + 2vmin);
}

.App-logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}

.App-main {
  width: 100%;
  height: 100%;
}

.logo-ics {
  margin-top: 10px;
  width: 20vmin;
}

.logo-gencat {
  margin-left: auto;
  width: 15vmin;
}

.App-link {
  color: #61dafb;
}

.form-container {
  width: 50%;
  background-color: #fff;
  padding: 20px;
  margin: 5% 25% 0 25%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

#login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container-inicio {
  display: flex;
  justify-content: center;
}

.form-container-inicio button {
  width: 50%;
}

.form-container-dw {
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
}

.info-login {
  background-color: #fff;
  margin: 15px;
  padding: 5px 20px;
  font-size: calc(5px + 1vmin);
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  text-align: justify;
}

input {
  width: 50%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 25%;
  padding: 10px;
  background-color: #6aaae4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #5a93c9;
}

.popup-content {
  border-radius: 4px;
}

.modal {
  padding: 10px;
}

.modal > h1 {
  text-align: center;
}

.popup-actions {
  width: 100%;
  padding: 10px 0px 0px 0px;
  margin: auto;
  text-align: center;
  display: flex;
}

.popup-actions > input {
  width: 45%;
  background-color: #6aaae4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: all 300ms;
}

.popup-actions > .aviso-no {
  margin-left: auto;
  background-color: #ABB2B9;
}

.popup-actions > .aviso-no:hover {
  margin-left: auto;
  background-color: #808B96;
}

.popup-actions > input:hover {
  background-color: #5a93c9;
}

.divNavBar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.navMenu {
  flex: auto;
  text-decoration: none;
  background-color: #808B96;
  margin-bottom: 100px;
}

.navLogout {
  flex: auto;
  text-decoration: none;
  background-color: #808B96;
  margin-bottom: 100px;
}

.navMenu ul {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  display: flex;
  list-style: none;
}

.navLogout ul {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  list-style: none;
}

nav li {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  margin: 0 15px 0 15px;
}

.btnLogout {
  cursor: pointer;
}

.btnCat {
  cursor: pointer;
  pointer-events: none;
  color: #ABB2B9;
}

li > .btnLogout {
  color: #fff;
  text-decoration: none;
  transition: all 300ms;
}

li > .btnLogout:hover {
  color:  #282c34;
  text-decoration: none;
  transition: all 300ms;
}

li > .btnCat {
  text-decoration: none;
  transition: all 300ms;
}

li > .btnCat:hover {
  color:  #282c34;
  text-decoration: none;
  transition: all 300ms;
}

.pAvisoNoData {
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

